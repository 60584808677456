import { MColor, MFlex, MFlexBlock, MFlexItem, MText, px } from '@mprise/react-ui'
import { Section } from '../../components/Section'
import { SwitchTransition } from 'react-transition-group'
import { CircularProgress, Collapse, Divider, Fade, IconButton } from '@material-ui/core'
import styled from '@emotion/styled'
import { useHistory } from '../../shared/use-history'
import Icon from '@mdi/react'
import { mdiCheck, mdiChevronRight, mdiStop } from '@mdi/js'
import { useTranslation } from 'react-i18next'
import { NotOptional } from '../../shared/typescript'
import {
  SearchWorkItemsDocument,
  WorkItemTemplateTaskOption,
  WorkStatus,
  WorkTaskByIdWithTaskResultsQuery,
  namedOperations,
  useFinishTaskMutation,
  useOutboundByWorkItemIdLazyQuery,
  useStartTaskMutation,
  useStopTaskMutation,
  useWorkTaskByIdWithTaskResultsLazyQuery,
} from '../../gateway/react.generated'
import { formatUnitOfMeasure } from '../../shared/formats'
import { CurrentResourceContainer } from '../../context/current-resource'
import { useEffect, useState } from 'react'
import { ConfirmFinishDialog } from './ConfirmFinishDialog'
import { TagStatus } from '../../components/TagStatus'
import { MaterialIcon } from '../../components/Icon'
import { getRefetchItemsSearchVariables } from './FilterDialog'
import { CurrentCompanyContainer } from '../../context/current-company'
import { fail } from '../../shared/typescript'

export type TaskValue = NotOptional<WorkTaskByIdWithTaskResultsQuery['workTask']>

export const TaskItem = ({
  taskId,
  workItemId,
  open,
  loading,
  onToggle,
}: {
  taskId: string
  workItemId: string
  open: boolean
  loading: boolean
  onToggle: (args: { taskId: string }) => void
}) => {
  const h = useHistory()
  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const companyId = currentCompany?.id ?? h.push('/')
  const resourceId = CurrentResourceContainer.useCurrent().current?.id ?? h.push('/')

  const [getTaskWithResults, taskQuery] = useWorkTaskByIdWithTaskResultsLazyQuery({ variables: { taskId: taskId } })

  useEffect(() => {
    getTaskWithResults()
  }, [])

  const task = taskQuery.data?.workTask
  const phaseCode = task?.workItem.phaseCode

  const canStart =
    task?.status === WorkStatus.Todo || task?.status === WorkStatus.OnHold || task?.status === WorkStatus.NotAssigned
  const canStop = task?.status === WorkStatus.InProgress
  const isDone = task?.status === WorkStatus.Done || task?.status === WorkStatus.Closed
  const isFinished = !loading && task?.status !== WorkStatus.Closed
  const isTodo = !loading && task?.status !== WorkStatus.Todo

  const taskOptions = task?.taskOptions ?? []

  const [showFinishConfirmDialog, setShowFinishConfirmDialog] = useState<boolean>(false)
  const showDoneDialog = () => {
    setShowFinishConfirmDialog(true)
  }

  const [startTaskMutation] = useStartTaskMutation()
  const [stopTaskMutation] = useStopTaskMutation()
  const [finishTask, finishTaskState] = useFinishTaskMutation()

  const handleClickStart = async () => {
    if (!task) {
      return
    }
    if (task.status !== WorkStatus.Done) {
      if (!resourceId) {
        fail('expects resource id')
      }

      await startTaskMutation({
        variables: {
          workItemId: workItemId,
          taskId: task.id,
          resourceIds: [resourceId],
          anonymousResourceCount: 0,
        },
      })
    }
    handleStartTaskRedirect(task)
  }

  const handleStartTaskRedirect = (task: any) => {
    if (task.jobInventoryPutAway && !task.itemConsumption) {
      if (taskOptions.length === 0) {
        h.push(`/transfer/job/${workItemId}/${task.id}/${phaseCode}`)
      } else if (taskOptions.includes(WorkItemTemplateTaskOption.PutOnCarriersGh)) {
        h.push(`/transfer/uploadcarriers/${workItemId}/${task.id}/${phaseCode}`)
      } else if (taskOptions.includes(WorkItemTemplateTaskOption.MoveCarriersToLocationGh)) {
        h.push(`/transfer/carriercodes/${workItemId}/${task.id}/${phaseCode}`)
      } else if (taskOptions.includes(WorkItemTemplateTaskOption.OffloadCarriersGh)) {
        h.push(`/transfer/carriercodes/${workItemId}/${task.id}/${phaseCode}`)
      }
    } else if (task.itemOutput) {
      h.push(`/transfer/job-pick/${workItemId}/${task.id}/${phaseCode}`)
    } else if (task.itemConsumption) {
      h.push(`/transfer/create-inventory/${workItemId}/${task.id}`)
    }
  }

  const handleClickStop = async () => {
    await stopTaskMutation({ variables: { workItemId: workItemId, taskId: taskId } })
  }

  const handleConfirmFinish = () => {
    setShowFinishConfirmDialog(false)

    if (!resourceId) {
      fail('expects resource id')
    }

    finishTask({
      variables: { workItemId: workItemId, taskId: taskId },
      refetchQueries: [
        namedOperations.Query.WorkTaskByIdWithTaskResults,
        {
          query: SearchWorkItemsDocument,
          variables: getRefetchItemsSearchVariables('my', companyId!, resourceId),
        },
        {
          query: SearchWorkItemsDocument,
          variables: getRefetchItemsSearchVariables('all', companyId!, resourceId),
        },
      ],
    })
  }

  if (!task) {
    return null
  }

  return (
    <Section>
      <div className='gh-taskItem-card'>
        <div className='gh-taskItem-card-header' onClick={() => onToggle({ taskId: task.id })}>
          <MFlexItem shrink={0}>
            <IconButton style={{ paddingLeft: '0' }}>
              <Icon path={mdiChevronRight} size={1} rotate={open ? 90 : 0} style={{ transition: `transform 0.3s` }} />
            </IconButton>
          </MFlexItem>
          <div style={{ flexGrow: 1 }}>
            {task?.order ?? `?`}. {task?.name ?? ``}
          </div>
        </div>
        <Collapse in={open}>
          <TaskDetails task={task} />
          <MFlexBlock justifyContent='space-around' padding={[2, 0, 0]}>
            <WowButton
              type='button'
              isBusy={loading}
              bgColor={canStart ? MColor.primary : canStop ? MColor.medium : MColor.status_todo}
              onClick={() => (canStart ? handleClickStart() : canStop ? handleClickStop() : null)}
            >
              <MText block textVariant='header' textColor={MColor.white}>
                <SwitchTransition>
                  <Fade key={canStart ? 1 : canStop ? 2 : loading ? 3 : 4}>
                    <div>
                      {canStart && <MaterialIcon value='play_arrow' style={{ fontSize: `3rem` }} />}
                      {canStop && <MaterialIcon value='pause' style={{ fontSize: `3rem` }} />}
                      {loading && <CircularProgress color='inherit' />}
                      {isDone && <Icon color={MColor.white} path={mdiCheck} size={2} />}
                    </div>
                  </Fade>
                </SwitchTransition>
              </MText>
            </WowButton>

            <WowButton
              type='button'
              isBusy={loading}
              bgColor={(isFinished && !isTodo) || isDone ? MColor.dim : MColor.high}
              onClick={e => {
                isTodo && !isDone ? showDoneDialog() : e.preventDefault()
              }}
            >
              <MText block textVariant='header' textColor={MColor.white}>
                <SwitchTransition>
                  <Fade key={canStart ? 1 : canStop ? 2 : loading ? 3 : 4}>
                    <div>
                      {(canStart || canStop) && <Icon color={MColor.white} path={mdiStop} size={2} />}
                      {isDone && <Icon color={MColor.white} path={mdiCheck} size={2} />}
                    </div>
                  </Fade>
                </SwitchTransition>
              </MText>
            </WowButton>
          </MFlexBlock>

          <ItemConsumptionSection itemConsumptionLines={task.itemConsumption} />
        </Collapse>
        <ConfirmFinishDialog
          open={showFinishConfirmDialog}
          task={task}
          onConfirmFinish={handleConfirmFinish}
          onCancelFinish={() => setShowFinishConfirmDialog(false)}
          finishTaskState={finishTaskState}
        />
      </div>
    </Section>
  )
}

interface WowButtonProps {
  isBusy: boolean
  bgColor: string
}
const WowButton = styled.button<WowButtonProps>`
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  margin: ${px(14)};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${px(36)};
  width: ${px(72)};
  height: ${px(72)};
  cursor: pointer;
  user-select: none;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;

  box-shadow: ${p =>
    p.isBusy
      ? `0 0 0 ${px(2)} ${p.bgColor}, 0 0 0 ${px(14)} ${MColor.white}, 0 0 0 ${px(16)} ${MColor.disabled}`
      : `0 0 0 ${px(6)} ${p.bgColor}, 0 0 0 ${px(10)} ${MColor.white}, 0 0 0 ${px(12)} ${MColor.disabled}`};

  :active {
    box-shadow:
      0 0 0 ${px(10)} ${p => p.bgColor},
      0 0 0 ${px(12)} ${MColor.white},
      0 0 0 ${px(14)} ${MColor.disabled};
  }
`

export const TaskDetails = ({
  task,
}: {
  task: TaskValue
}) => {
  const { t } = useTranslation()
  const workItemId = task.workItem.id
  const plannedItemOutput = task.itemOutput ? task.itemOutput[0]?.planned : null
  const plannedJobInventoryPutAway = task.jobInventoryPutAway ? task.jobInventoryPutAway[0]?.planned : null
  const plannedItemConsumption = task.itemConsumption ? task.itemConsumption[0]?.planned : null

  // Careful: position in planned is sometimes planned/current
  const plannedPosition =
    plannedJobInventoryPutAway?.position?.code ||
    plannedItemOutput?.outputPosition?.code ||
    (plannedItemConsumption ? task.workItem.plannedPositions[0]?.code : null)

  const currentPosition = plannedItemOutput?.position?.code

  const plannedQuantity = (task?.workItem.plannedQuantity ?? 0).toLocaleString('nl-NL')
  const unitOfMeasure = formatUnitOfMeasure(task?.workItem.unitOfMeasure)

  const [getReportedQuantity, reportedQuantityQuery] = useOutboundByWorkItemIdLazyQuery({
    fetchPolicy: `network-only`,
  })

  useEffect(() => {
    if (task.jobInventoryPutAway && !task.itemConsumption && workItemId) {
      getReportedQuantity({
        variables: {
          workItemId: workItemId
        }
      })
    }
  }, [])

  let reportedQuantity = 0
  if (task.jobInventoryPutAway && !task.itemConsumption) {
    // get from outbound
    reportedQuantity = (reportedQuantityQuery.data?.outboundJobInventoryByWorkItemId ?? []).reduce((a, b) => a + (b?.quantity ?? 0),0)
  } else if (task.itemOutput) {
    // get from cosmos
    reportedQuantity = task.itemOutput![0]?.reported.reduce((a, b) => a + (b.quantity ?? 0), 0) ?? 0
  } else if (task.itemConsumption && task.jobInventoryPutAway) {
    //get from cosmos
    reportedQuantity = task.jobInventoryPutAway![0]?.reported.reduce((a, b) => a + (b.quantity ?? 0), 0) ?? 0
  }

  return (
    <div className='gh-taskItem-card-content'>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>{task.workItem.jobs?.[0]?.code}</div>
        <TagStatus status={task.status} />
      </div>
      <div>{task.workItem.itemDescription}</div>
      <div>{`${t(`QUANTITY`)}: ${plannedQuantity} ${unitOfMeasure}`}</div>
      <div>{`${t(`ACTUAL`)}: ${reportedQuantity.toLocaleString('nl-NL')} ${unitOfMeasure}`}</div>
      {currentPosition && <div>{`${t(`CURRENT_POSITION`)}: ${currentPosition}`}</div>}
      {plannedPosition && <div>{`${t(`PLANNED_POSITION`)}: ${plannedPosition}`}</div>}
    </div>
  )
}

const ItemConsumptionSection = ({ itemConsumptionLines }: { itemConsumptionLines: any }) => {
  const { t } = useTranslation()

  if (!itemConsumptionLines || itemConsumptionLines.length < 1) {
    return null
  }

  return (
    <>
      <div style={{ marginTop: '1.5rem' }}>{t(`WorkResultType.ITEM_CONSUMPTION`)}</div>

      <div className='gh-taskItem-card-content'>
        {itemConsumptionLines.map((consumptionLine: any, index: number) => {
          let totalReportedQuantity = 0
          const reportedQuantities = []
          const reportedLotNrs = []
          for (let i = 0;i < consumptionLine.reported?.length ?? 0;i++) {
            const reportedLine = consumptionLine.reported[i]
            totalReportedQuantity += reportedLine.quantity
            const reportedUnitOfMeasure = formatUnitOfMeasure(reportedLine.quantityUnit)
            reportedQuantities.push(
              <div key={i}>
                {reportedLine.quantity?.toLocaleString('nl-NL')} {reportedUnitOfMeasure}
              </div>,
            )
            reportedLotNrs.push(<div key={i}>{reportedLine.lotNumbers?.map((l: any) => l.lotNumber).join(', ')}</div>)
          }

          const plannedUnitOfMeasure = formatUnitOfMeasure(consumptionLine.planned.quantityUnit)
          return (
            <div key={index}>
              <Divider style={{ margin: '5px 0' }} />
              <MFlex>
                <MFlexItem shrink={0}></MFlexItem>
                <MFlex vertical style={{ lineHeight: '1.5rem', width: '100%', maxWidth: '400px', paddingRight: '3px' }}>
                  <div>{consumptionLine.planned.itemDescription}</div>
                  <MFlex style={{ lineHeight: '1.5rem', whiteSpace: 'nowrap' }} justifyContent='space-between' gap={8}>
                    <div>
                      {t(`PLANNED`)}: {consumptionLine.planned.quantity.toLocaleString('nl-NL')} {plannedUnitOfMeasure}
                    </div>
                    <div>
                      {t(`ACTUAL`)}: {totalReportedQuantity.toLocaleString('nl-NL')}
                    </div>
                  </MFlex>
                </MFlex>
              </MFlex>
            </div>
          )
        })}
      </div>
    </>
  )
}
